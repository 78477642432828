import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-21f00353")
const _hoisted_1 = {
  key: 0,
  class: "projects"
}
const _hoisted_2 = {
  key: 1,
  class: "user-actions"
}
const _hoisted_3 = {
  line: "",
  class: "title-2"
}
const _hoisted_4 = { class: "link-user-form" }
const _hoisted_5 = ["value", "label"]
const _hoisted_6 = ["value"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  line: "",
  class: "title-2"
}
const _hoisted_10 = { class: "unlink-user-form" }
const _hoisted_11 = ["value", "label"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["disabled"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_card_user_detail = _resolveComponent("card-user-detail")!
  const _component_card_project = _resolveComponent("card-project")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_ctx.userId)
    ? (_openBlock(), _createBlock(_component_base_container, {
        key: 0,
        marginTop: "",
        marginBottom: "",
        class: "view-user"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_base_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_card_user_detail, { userId: _ctx.userId }, null, 8, ["userId"]),
              (_ctx.projects.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
                      return (_openBlock(), _createBlock(_component_card_project, {
                        key: project.id,
                        project: project
                      }, null, 8, ["project"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isSuperAdmin && !_ctx.isUserSuperAdmin)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", null, [
                      _createElementVNode("dig-ui-title", _hoisted_3, _toDisplayString(_ctx.$t('linkUserToProject')), 1),
                      _createElementVNode("div", _hoisted_4, [
                        (_ctx.leftProjects.length)
                          ? (_openBlock(), _createElementBlock("dig-input-select-picker", {
                              key: 0,
                              ref: "selectLinkEl",
                              required: "",
                              value: _ctx.selectedLinkProject,
                              onSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateLink($event.detail))),
                              label: _ctx.$t('selectProject')
                            }, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leftProjects, (project) => {
                                return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
                                  key: project.id,
                                  value: project.id
                                }, _toDisplayString(project.name), 9, _hoisted_6))
                              }), 128))
                            ], 40, _hoisted_5))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          _createElementVNode("dig-ui-standard-button", {
                            disabled: _ctx.loading || !_ctx.leftProjects.length,
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.linkUserToProject()))
                          }, _toDisplayString(_ctx.$t('linkToProject')), 9, _hoisted_7)
                        ])
                      ])
                    ]),
                    (_ctx.isSuperAdmin && !_ctx.isUserSuperAdmin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("dig-ui-title", _hoisted_9, _toDisplayString(_ctx.$t('unlinkUserToProject')), 1),
                          _createElementVNode("div", _hoisted_10, [
                            (_ctx.linkedProjects.length)
                              ? (_openBlock(), _createElementBlock("dig-input-select-picker", {
                                  key: 0,
                                  ref: "selectUnlinkEl",
                                  required: "",
                                  value: _ctx.selectedUnlinkProject,
                                  onSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateUnlink($event.detail))),
                                  label: _ctx.$t('selectProject')
                                }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.linkedProjects, (project) => {
                                    return (_openBlock(), _createElementBlock("dig-input-select-picker-option", {
                                      key: project.id,
                                      value: project.id
                                    }, _toDisplayString(project.name), 9, _hoisted_12))
                                  }), 128))
                                ], 40, _hoisted_11))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", null, [
                              _createElementVNode("dig-ui-standard-button", {
                                disabled: _ctx.loading || !_ctx.linkedProjects.length,
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.unlinkUserToProject()))
                              }, _toDisplayString(_ctx.$t('unlinkToProject')), 9, _hoisted_13)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}