
import {
  defineComponent,
  SetupContext,
  computed,
  ref,
  watch,
  onMounted,
} from 'vue'
import UserFormCard from '@/components/core/user/UserFormCard.vue'
import { User } from '@/model/User'
import { USER_ROLES } from '@/model/User'
import { Toaster } from '@/common/Toaster'
import router from '@/router'
import { __ } from '@/locales'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { storeUser, StoreGettersUser, StoreActionsUser } from '@/store/user'

export default defineComponent({
  name: `CardUserDetail`,
  components: { UserFormCard },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  emits: ['validate', 'cancel'],
  setup(
    props: Readonly<{
      userId: number
    }>,
    context: SetupContext,
  ) {
    const disabledFormButton = ref(false)
    const editing = ref(false)
    const loading = ref(false)
    const loadingAskActivation = ref(false)

    onMounted(async () => {
      loading.value = true
      storeUser.dispatch(StoreActionsUser.GET_IMAGE, props.userId)
      await storeUser.dispatch(StoreActionsUser.GET, props.userId)
      loading.value = false
    })

    watch(
      () => props.userId,
      async () => {
        if (!props.userId) return
        if (props.userId === user.value.id) return

        loading.value = true
        storeUser.dispatch(StoreActionsUser.GET_IMAGE, props.userId)
        await storeUser.dispatch(StoreActionsUser.GET, props.userId)
        loading.value = false
      },
    )

    const src = computed(() => storeUser.getters[StoreGettersUser.IMAGE])
    const isSuperadmin = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const user = computed((): User => storeUser.getters[StoreGettersUser.USER])
    const canEditImage = computed(
      () =>
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN ||
        storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.ADMIN,
    )
    const canEdit = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )

    const updateUser = async (user: User) => {
      disabledFormButton.value = true
      const res = await storeUser.dispatch(StoreActionsUser.UPDATE, {
        user: user,
        id: props.userId,
      })
      Toaster.toast(res)
      if (res.success) {
        editing.value = false
      }
      disabledFormButton.value = false
    }

    const deleteUser = async () => {
      if (confirm(__(`User.askDelete`))) {
        const res = await storeUser.dispatch(
          StoreActionsUser.DELETE,
          props.userId,
        )
        Toaster.toast(res)
        if (res.success) {
          router.back()
        }
      }
    }

    const saveUserImage = async (event: CustomEvent) => {
      const file = event.detail.file
      const res = await storeUser.dispatch(StoreActionsUser.UPDATE_IMAGE, {
        base64: event.detail.base64,
        file: { name: file.name },
        userId: props.userId,
      })
      Toaster.toast(res)
    }

    const deleteUserImage = async () => {
      const res = await storeUser.dispatch(
        StoreActionsUser.DELETE_IMAGE,
        props.userId,
      )
      Toaster.toast(res)
    }

    const askActivation = async () => {
      loadingAskActivation.value = true
      const res = await storeUser.dispatch(
        StoreActionsUser.ASK_ACTIVATION,
        props.userId,
      )
      loadingAskActivation.value = false
      Toaster.toast(res)
    }

    return {
      src,
      user,
      editing,
      isSuperadmin,
      canEditImage,
      canEdit,
      updateUser,
      deleteUser,
      saveUserImage,
      deleteUserImage,
      disabledFormButton,
      askActivation,
      loadingAskActivation,
      loading,
    }
  },
})
