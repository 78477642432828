
import { defineComponent, onMounted, ref, Ref, computed } from 'vue'
import CardUserDetail from '@/components/core/user/CardUserDetail.vue'
import CardProject from '@/components/core/project/CardProject.vue'
import { useRoute } from 'vue-router'
import { Toaster } from '@/common/Toaster'
import { Project } from '@/model/Project'
import { USER_ROLES, User } from '@/model/User'
import { storeAuth, StoreGettersAuth } from '@/store/auth'
import { StoreActionsUser, storeUser, StoreGettersUser } from '@/store/user'
import { storeProject, StoreGettersProject } from '@/store/project'
import { DigInputSelectPicker } from '@digithia/input/select'

export default defineComponent({
  name: `ViewUser`,
  components: { CardUserDetail, CardProject },
  props: {},
  emits: [],
  setup() {
    const route = useRoute()
    const loading = ref(false)
    const selectedLinkProject = ref('')
    const selectedUnlinkProject = ref('')
    const userId = ref(0)
    const selectLinkEl: Ref<DigInputSelectPicker | null> = ref(null)
    const selectUnlinkEl: Ref<DigInputSelectPicker | null> = ref(null)

    const projects: Ref<Project[]> = computed(
      () => storeUser.getters[StoreGettersUser.PROJECTS],
    )
    const allProjects: Ref<Project[]> = computed(
      () => storeProject.getters[StoreGettersProject.PROJECTS],
    )
    const linkedProjects: Ref<Project[]> = computed(() =>
      allProjects.value.filter((p: Project) => {
        return projects.value.find((proj: Project) => proj.id === p.id)
      }),
    )
    const leftProjects: Ref<Project[]> = computed(() =>
      allProjects.value.filter((p: Project) => {
        return !projects.value.find((proj: Project) => proj.id === p.id)
      }),
    )
    const isSuperAdmin: Ref<boolean> = computed(
      () => storeAuth.getters[StoreGettersAuth.ROLE] === USER_ROLES.SUPERADMIN,
    )
    const isUserSuperAdmin: Ref<boolean> = computed(
      () => user.value.role === USER_ROLES.SUPERADMIN,
    )
    const user = computed((): User => storeUser.getters[StoreGettersUser.USER])

    onMounted(async () => {
      let id = +route.params.id

      if (!id) {
        id = storeAuth.getters[StoreGettersAuth.USER].id
      }

      userId.value = id
      await storeUser.dispatch(StoreActionsUser.GET_PROJECTS, id)
    })

    const linkUserToProject = async () => {
      loading.value = true
      const res = await storeUser.dispatch(StoreActionsUser.LINK_TO_PROJECT, {
        userId: userId.value,
        projectId: selectedLinkProject.value,
      })
      Toaster.toast(res)
      if (res.success) {
        selectedLinkProject.value = ''
        selectLinkEl.value?.reset()
        storeUser.dispatch(StoreActionsUser.GET_PROJECTS, userId.value)
      }
      loading.value = false
    }

    const unlinkUserToProject = async () => {
      loading.value = true
      const res = await storeUser.dispatch(StoreActionsUser.UNLINK_TO_PROJECT, {
        userId: userId.value,
        projectId: selectedUnlinkProject.value,
      })
      Toaster.toast(res)
      if (res.success) {
        selectedUnlinkProject.value = ''
        selectUnlinkEl.value?.reset()
        storeUser.dispatch(StoreActionsUser.GET_PROJECTS, userId.value)
      }
      loading.value = false
    }

    const updateLink = (value: string) => {
      selectedLinkProject.value = value
    }
    const updateUnlink = (value: string) => {
      selectedUnlinkProject.value = value
    }

    return {
      loading,
      userId,
      selectedLinkProject,
      selectedUnlinkProject,
      projects,
      isSuperAdmin,
      linkUserToProject,
      unlinkUserToProject,
      isUserSuperAdmin,
      updateLink,
      updateUnlink,
      linkedProjects,
      leftProjects,
      selectLinkEl,
      selectUnlinkEl,
    }
  },
})
